export const ColorTable = {
  initial: {
    user: "#03a9f429",
    ai: "#03a9f454",
  },
  general: {
    user: "#f4860329",
    ai: "#f49c0354",
  },
  general_spec_men: {
    user: "#03f44a29",
    ai: "#03f44a54",
  },
  general_spec_women: {
    user: "#f4036a29",
    ai: "#f4036a54",
  },
  age_spec_children: {
    user: "#0f03f429",
    ai: "#0f03f454",
  },
  age_spec_elderly: {
    user: "#f4640329",
    ai: "#f4640354",
  },
  lab_general: {
    user: "#f4860329",
    ai: "#f49c0354",
  },
  lab_men: {
    user: "#03f44a29",
    ai: "#03f44a54",
  },
  lab_women: {
    user: "#f4036a29",
    ai: "#f4036a54",
  },
  lab_children: {
    user: "#0f03f429",
    ai: "#0f03f454",
  },
  lab_elderly: {
    user: "#f4640329",
    ai: "#f4640354",
  },
  end: {
    user: "#15f40329",
    ai: "#15f40354",
  },
};
