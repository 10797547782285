import { useEffect, useCallback } from "react";
import useAuth from "../lib/auth";
import { Typography } from "@mui/material";
import Particles from "react-particles";
import { loadFull } from "tsparticles";

const Successful = () => {
  const { logout } = useAuth();

  useEffect(() => {
    logout();
  }, [logout]);

  const particlesInit = useCallback(async (engine) => {
    console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  return (
    <>
      <Typography
        variant="h4"
        component="h4"
        sx={{ m: 3, textAlign: "center" }}
      >
        Przesłano pomyślnie
      </Typography>
      <Typography
        variant="body"
        component="h4"
        sx={{ m: 3, textAlign: "center" }}
      >
        Bardzo dziękujemy za poświęcony czas. Do zobaczenia na wizycie
      </Typography>
      <Particles
        className="particle-container"
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          background: {
            color: {
              value: "#fff",
            },
          },
          fpsLimit: 120,
          interactivity: {
            events: {
              resize: true,
            },
            modes: {
              push: {
                quantity: 2,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
            },
          },
          particles: {
            color: {
              value: "#000",
            },
            links: {
              color: "#000",
              distance: 200,
              enable: true,
              opacity: 0.1,
              width: 0.3,
            },
            collisions: {
              enable: true,
            },
            move: {
              direction: "none",
              enable: true,
              outModes: {
                default: "bounce",
              },
              random: false,
              speed: 1,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 1000,
              },
              value: 80,
            },
            opacity: {
              value: 0.3,
            },
            shape: {
              type: "circle",
            },
            size: {
              value: { min: 1, max: 3 },
            },
          },
          detectRetina: true,
        }}
      />
    </>
  );
};

export default Successful;
