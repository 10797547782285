import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../lib/auth";
import { useEffect } from "react";

const Token = () => {
  const { login, isAuthenticated } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const order = queryParams.get("order");

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/chat");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    try{
      fetch(`/api/token/${order}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(async (res) => {
          const data = await res.json();
          const token = data.token;
  
          if (token !== null) {
            login(token);
          } else {
            console.log("Token is not provided.");
          }
        })
        .catch((err) => {
          console.log("Can't find information for your order.");
        });
    } catch (err) {
      
    }
    navigate("/chat");
  }, [order]);

  return <>Redirecting...</>;
};

export default Token;
