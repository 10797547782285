import CircularProgress from "@mui/material/CircularProgress";
import ReactLoading from "react-loading";
import LinearProgress from "@mui/material/LinearProgress";

const Loading = () => {
  return (
    <div className="loading-container">
      <LinearProgress
        color="info"
        sx={{ position: "fixed", top: 0, width: "100%" }}
      />
      <ReactLoading type="bars" color="#2196f3" />
      <LinearProgress
        color="info"
        sx={{ position: "fixed", bottom: 0, width: "100%" }}
      />
    </div>
  );
};

export default Loading;
