import { useEffect, useState } from "react";
import DialogFlow from "../components/DialogFlow";
import useAuth from "../lib/auth";
import { Navigate } from "react-router-dom";
import Loading from "./Loading";
import { Typography } from "@mui/material";

const ChatRoom = () => {
  const { user, getAccessTokenSilently } = useAuth();

  const [info, setInfo] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (user !== null && user !== undefined) {
      fetch("/api/patient", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getAccessTokenSilently()}`,
        },
      }).then(async (res) => {
        const data = await res.json();
        setInfo(data);
        setIsLoading(false)
      })
    }
  }, [user])

  return user === null || user === undefined ? (
    <Typography variant="body" component="h4" sx={{ textAlign: "center" }}>
      Invalid Token
    </Typography>
  ) : isLoading ? <Loading /> : (
    <DialogFlow user={user} info={info}/>
  );
};

export default ChatRoom;
