import React, { useState, useEffect, useRef } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import ReactLoading from "react-loading";
import useAuth from "../lib/auth";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import {
  Typography,
  Box,
  List,
  ListItem,
  TextField,
  Button,
} from "@mui/material";
import ButtonGroup from "@mui/material/ButtonGroup";
import SendIcon from "@mui/icons-material/Send";
import { ColorTable } from "../lib/color";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

function Footer() {
  return (
    <Box
      sx={{
        width: "100%",
        position: "fixed",
        bottom: 50,
        backgroundColor: "#f1f1f1",
        color: "#2d2d2d",
        textAlign: "center",
        p: 2,
      }}
    >
      <Typography variant="body1">
        Your Company © {new Date().getFullYear()}
      </Typography>
    </Box>
  );
}

const InputArea = styled("div")(({ theme }) => ({
  position: "fixed",
  bottom: 0,
  width: "100%",
  display: "flex",
  justifyContent: "center",
  backgroundColor: "#fff",
}));

const Message = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  wordBreak: "break-word",
  width: "100%",
  borderRadius: theme.spacing(0.5),
}));

export default function DialogFlow({ user, info }) {
  const [inputValue, setInputValue] = React.useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { getAccessTokenSilently } = useAuth();
  const [isLoading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [questionType, setQuestionType] = useState("");

  const navigate = useNavigate();

  const getQuestion = async (messages) => {
    const res = await fetch("/api/chat", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessTokenSilently()}`,
      },
      body: JSON.stringify({
        msg: messages.length === 0 ? "" : messages[messages.length - 1].content,
      }),
    });
    const data = await res.json();

    return data;
  };

  const handleSubmit = async (status) => {
    setIsSubmitting(true);

    await fetch("/api/chat/summarize", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessTokenSilently()}`,
      },
      body: JSON.stringify({
        status,
      }),
    });

    navigate("/success");

    setIsSubmitting(false);
  };

  const [expTime, setExpTime] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = Date.now() / 1000; // get current time in seconds
      const remainingTime = user.exp - now; // calculate remaining time

      // check if the remaining time is less than or equal to 0
      if (remainingTime <= 0) {
        clearInterval(interval); // if so, clear the interval
        setExpTime("Session Expired");
      } else {
        const minutes = Math.floor(remainingTime / 60);
        const seconds = Math.floor(remainingTime % 60);
        setExpTime(`Sesja kończy się za: ${minutes} min ${seconds} sek`);
      }
    }, 1000);

    return () => clearInterval(interval); // cleanup on unmount
  }, [user]);

  const handleSend = () => {
    if (inputValue !== "") {
      const newMessages = [
        ...messages,
        {
          role: "user",
          content: inputValue,
        },
      ];
      setMessages(newMessages);
      setInputValue("");

      setLoading(true);
      getQuestion(newMessages)
        .then((res) => {
          setMessages(res);
        })
        .finally(() => setLoading(false));
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      setLoading(true);
      isFirstRender.current = false;
      getQuestion(messages)
        .then((res) => {
          setMessages(res);
        })
        .finally(() => setLoading(false));
    }
  }, [getQuestion]);

  const bottomListRef = useRef();

  useEffect(() => {
    if (bottomListRef.current) {
      bottomListRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (messages) {
      const lastMessage = messages[messages.length - 1];
      if (lastMessage && lastMessage.role === "assistant") {
        setQuestionType(JSON.parse(lastMessage.content).type);
      }
    }
  }, [messages]);

  useEffect(() => {
    if (questionType === "end") {
      handleSubmit("ended");
    }
  }, [questionType]);

  return (
    <>
      <Typography
        sx={{ mt: 3, textAlign: "center", fontWeight: "800", p: 1 }}
        variant="h5"
        component="h5"
      >
        Witamy w naszej ankiecie zdrowotnej.
      </Typography>
      <div className="exit-button" onClick={() => handleSubmit("paused")}>
        <ExitToAppIcon sx={{ ml: "17px", mt: "17px" }} />
      </div>
      <Typography
        sx={{ textAlign: "center", color: "#567b99" }}
        variant="caption"
        component="h5"
      >
        Prosimy o jej dokładne wypełnienie co pozwoli na znaczną poprawę
        diagnostyki. Dziękujemy {expTime}
      </Typography>
      <Box sx={{ maxWidth: 500, m: "auto", mb: 8 }}>
        <List sx={{ p: 0 }}>
          {messages.map((message, index) => (
            <ListItem
              key={index}
              alignItems="flex-start"
              sx={{
                p: 0.1,
                pr: message.role === "assistant" ? 3 : 1,
                pl: message.role === "assistant" ? 1 : 3,
                flexDirection:
                  message.role === "assistant" ? "row" : "row-reverse",
              }}
              ref={index === messages.length - 1 ? bottomListRef : null}
            >
              <Message
                sx={{
                  bgcolor:
                    message.role === "assistant"
                      ? ColorTable[JSON.parse(message.content).type].ai
                      : "#9d9d9d29",
                  textAlign: message.role === "assistant" ? "left" : "right",
                }}
              >
                <Typography variant="caption" display="block" gutterBottom>
                  <b>
                    {message.role === "assistant" ? "Endokrynolog" : info.name}
                  </b>
                </Typography>
                <Typography variant="body">
                  {message.role === "assistant"
                    ? JSON.parse(message.content).question
                    : message.content}
                </Typography>
              </Message>
            </ListItem>
          ))}
        </List>
        {isLoading ? <LinearProgress sx={{ ml: 1, mr: 1 }} /> : null}
      </Box>
      <InputArea sx={{ backgroundColor: "#f9f9f9" }}>
        <TextField
          value={inputValue}
          onChange={handleInputChange}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault(); // Prevent form submission
              handleSend();
            }
          }}
          placeholder="Tutaj wpisz swoją wiadomosć..."
          variant="standard"
          sx={{ margin: 1 }}
          autoComplete="off"
          fullWidth
        />
        <Button
          sx={{ margin: 1 }}
          variant="contained"
          color="info"
          onClick={handleSend}
        >
          <SendIcon />
        </Button>
      </InputArea>
      {isSubmitting ? (
        <div
          className="loading-container"
          style={{
            width: "100%",
            height: "100%",
            position: "fixed",
            top: 0,
            backgroundColor: "#00000061",
          }}
        >
          <ReactLoading type="bars" color="#2196f3" />
        </div>
      ) : null}
    </>
  );
}
