import { useState, useEffect, createContext, useContext } from "react";
import jwt from "jwt-decode";
import { useNavigate } from "react-router-dom";

// Create a context with a dummy default value
const AuthContext = createContext(undefined);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [token, setToken] = useState(sessionStorage.getItem("token"));
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();

  const scheduleTokenExpiryLogout = (exp) => {
    const now = Date.now() / 1000; // get the current date and time in seconds
    const delay = exp - now; // calculate the remaining time until the token expires

    // check if the delay is greater than 0
    if (delay > 0) {
      // set up a timeout that logs out the user when the token expires
      setTimeout(() => {
        logout();
      }, delay * 1000); // convert delay to milliseconds
    } else {
      // if the token has already expired, logout immediately
      logout();
    }
  };

  const login = async (access_token) => {
    setToken(access_token);
    const userFromToken = jwt(access_token);

    const userId = userFromToken.sub;
    const key = userFromToken.key;
    const exp = userFromToken.exp;

    if (
      userId === undefined ||
      key === undefined
    ) {
      throw new Error("Token is invalid.");
    }

    setUser({
      userId,
      key,
      exp,
    });

    setIsAuthenticated(true);
    sessionStorage.setItem("token", access_token);

    scheduleTokenExpiryLogout(userFromToken.exp);
  };

  const logout = () => {
    setToken(null);
    setUser(null);
    setIsAuthenticated(false);
    sessionStorage.removeItem("token");
  };

  const getAccessTokenSilently = () => {
    return token;
  };

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (token) {
      setIsAuthenticated(true);
      setToken(token);

      const userFromToken = jwt(token);

      setUser({
        userId: userFromToken.sub,
        key: userFromToken.key,
        exp: userFromToken.exp,
      });

      scheduleTokenExpiryLogout(userFromToken.exp);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  useEffect(() => {
    if (typeof isAuthenticated === "undefined") {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isAuthenticated]);

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        login,
        user,
        isAuthenticated,
        getAccessTokenSilently,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export default useAuth;
