import { useState } from "react";
import "./App.css";
import { Route, Navigate, Routes, useLocation, Outlet } from "react-router-dom";
import ChatRoom from "./pages/ChatRoom";
import Token from "./pages/Token";
import Successful from "./pages/Successful";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Navigate to="/token" replace />} />
        <Route path="/token" element={<Token />} />
        <Route path="/chat" element={<ChatRoom />} />
        <Route path="/success" element={<Successful />} />
      </Routes>
    </div>
  );
}

export default App;
